import {
    Box,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
} from "@chakra-ui/react";
import text from "constants/applicationText";
import { BsExclamationDiamond } from "react-icons/bs";

interface Props {
    children: string;
}

export const UnexpectedError = ({ children }: Props) => {
    return (
        <Container centerContent>
            <Flex alignItems="center" justifyContent="center" mt="40%">
                <Stack direction="row" gap={2}>
                    <Icon
                        as={BsExclamationDiamond}
                        w="3.125rem"
                        h="3.75rem"
                        color="status.warning.50"
                    />
                    <Box maxW="sm">
                        <Heading
                            color="status.warning.50"
                            fontSize="5xl"
                            mb="4"
                        >
                            {text.components.unexpectedError.title}
                        </Heading>
                        <Text
                            fontWeight={700}
                            color="red"
                            fontSize="xl"
                            textAlign="center"
                        >
                            {text.components.unexpectedError.code}: {children}
                        </Text>
                    </Box>
                </Stack>
            </Flex>
        </Container>
    );
};
